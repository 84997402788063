// Helper styles for demo

import React, { useRef } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import typography from '../utils/typography'

const borders = {
  border: `${typography.rhythm(0.1)} solid ${process.env.BLUE_BLACK}`,
  padding: `${typography.rhythm(0.1)} ${typography.rhythm(0.2)}`
}

const s = {

  label: {
    display: 'block',
    marginBottom: typography.rhythm(0.5),
    textTransform: 'uppercase'
  },

  text: {
    width: '100%',
    display: 'block',
    ...borders
  },

  textarea: {
    minHeight: typography.rhythm(5),
    width: '100%',
    display: 'block',
    resize: 'vertical',
    ...borders
  },

  wrapFeedback: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  feedback: {
    margin: `-${typography.rhythm(0.5)} -${typography.rhythm(0.25)} 0`,
    background: process.env.BLUE_BLACK,
    color: 'white',
    display: 'inline-block',
    height: typography.rhythm(1),
    padding: `0 ${typography.rhythm(0.25)}`,
    textTransform: 'none'
  },

  nav: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: `${typography.rhythm()} -${typography.rhythm(0.25)}`
  },

  button: {
    color: 'white',
    background: process.env.BLUE_BLACK,
    margin: `0 ${typography.rhythm(0.25)}`,
    border: 0,
    cursor: 'pointer'
  }

}

const MessageForm = () => {
  const form = useRef(null);
  return (
    <div>
      <Formik
        initialValues={{ email: '', message: '' }}
        onSubmit={() => {
          form.current.setAttribute('action','https://formspree.io/louisafricandance@yahoo.co.uk');
          form.current.setAttribute('method','POST');
          form.current.submit();
        }}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .required('Required'),
          message: Yup.string()
            .min(15, 'Message too Short')
            .max(680, 'Message too Long')
            .required('Required'),
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleReset,
            handleSubmit
          } = props
          return (
            <form ref={form} onSubmit={handleSubmit}>
              {/* <small>{JSON.stringify(props, null, '\t')}</small> */}
              <label css={s.label} htmlFor="email">
                Email
                <input
                  name="email"
                  css={s.text}
                  id="email"
                  placeholder="Your email address"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  className={
                    errors.email && touched.email
                      ? 'text-input error'
                      : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div css={s.wrapFeedback}><span css={s.feedback}>{errors.email}</span></div>
                )}
              </label>

              <label css={s.label}>
                Message
                <textarea
                  name="message"
                  id="message"
                  placeholder="Your message"
                  onChange={handleChange}
                  css={s.textarea}
                  value={values.message}
                />
                {errors.message && touched.message && (
                  <div css={s.wrapFeedback}><span css={s.feedback}>{errors.message}</span></div>
                )}
              </label>

              <nav css={s.nav}>
                <button
                  css={s.button}
                  type="button"
                  className="outline"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  Reset
                </button>
                {}
                <button type="submit" css={s.button} disabled={isSubmitting}>
                  Submit
                </button>
              </nav>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default MessageForm
