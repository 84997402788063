import React from 'react'
import typography from '../utils/typography'
import Header from '../components/header'
import Seo from '../components/seo'

const pageContentCss = {
  marginBottom: typography.rhythm(1.5)
}

const Template = ({ children, title }) => {
  return (<>
    <Seo title={title} />
    <div css={pageContentCss}>
      <div>
        {title && <Header title={title} />}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: children }}
        />
    </div>
  </>)
}

export default Template;
