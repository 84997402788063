import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PageContent from '../components/page-content'
import MessageForm from '../components/message-form'
import Grid from '../components/grid'
import typography from '../utils/typography'
import { LAYOUT_WIDTH } from '../variables';


const columnSmall = {
  [`@media (max-width: ${typography.rhythm(LAYOUT_WIDTH)})`]: {
    gridTemplateColumns: '1fr',
  }
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
        <Grid css={columnSmall}>
          <PageContent title={frontmatter.title}>{html}</PageContent>
          <MessageForm />
        </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query contactPageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
      }
      timeToRead
    }
  }
`

