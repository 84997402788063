import React from 'react'

const Header = ({ children, title }) => (
    <header>
        <h2 style={{textTransform: 'uppercase'}}>{title}</h2>
        <hr />
        {children}
    </header>
)

export default Header
