import React from 'react'
import typography from '../utils/typography'
import { LAYOUT_WIDTH } from '../variables';

const cssGrid = {
  display: 'grid',
  gridTemplateColumns: `1fr 1fr`,
  gridGap: typography.rhythm(2),
  [`@media (max-width: ${typography.rhythm(LAYOUT_WIDTH)})`]: {
    gridTemplateColumns: '1fr',
    gridGap: typography.rhythm(0),
  }
}

const Grid = ({ children }) => <div css={cssGrid}>{children}</div>

export default Grid
